import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function About() {
    useEffect(() => {
        AOS.init({ duration: 1000 })
    }, [])
    return (
        <section className="about-area page-section scroll-to-page" id="about">
            <div className="custom-container">
                <div className="about-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                            <i className="lar la-user"></i> About
                        </h4>
                        <h1 className="scroll-animation" data-aos='fade-up'>Seasoned product thinker.<br />Appetite for solving <span>complex business problems</span>.
                        </h1>
                    </div>
                    <p className="scroll-animation" data-aos='fade-up'>As a Design Technologist deeply immersed in GenAI for the past 18 months, I bring cutting-edge expertise in creating AI-powered user experiences and product strategies. I excel at translating complex GenAI capabilities into intuitive, user-friendly interfaces, ensuring seamless adoption and maximum impact for businesses venturing into the AI space.<br></br><br></br>My unique blend of AI knowledge, design thinking, and technical skills enables me to accelerate innovation in GenAI integration, from rapid prototyping to scalable solutions. I am adept at bridging the gap between AI engineering and user-centric design, making me an ideal asset for teams looking to go-to-market with GenAI or embed AI capabilities into large-scale products.</p>
                    <p className="scroll-animation" data-aos='fade-up'>I get people and it works the other way around too. I've played as Lone Wolf, Pack Member and Leader.</p>
                </div>
            </div>
        </section>
    )
}
