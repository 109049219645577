import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

const styles = {
    specializationsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
    },
    specializationItem: {
        backgroundColor: '#1e1e1e',
        borderRadius: '10px',
        overflow: 'hidden',
    },
    specializationInner: {
        display: 'flex',
        alignItems: 'center',
    },
    imageContainer: {
        flex: '0 0 200px',
        padding: '1rem',
    },
    image: {
        width: '100%',
        height: 'auto',
        borderRadius: '5px',
    },
    contentContainer: {
        flex: 1,
        padding: '1rem',
    },
    title: {
        color: '#ffffff',
        marginBottom: '0.5rem',
    },
    subtitle: {
        color: '#e0e0e0',
        marginBottom: '1rem',
    },
    description: {
        color: '#b0b0b0',
    },
};

const SpecializationItem = ({ title, subtitle, description, imageSrc, imageAlt }) => (
    <div className="specialization-item" data-aos='fade-up' style={styles.specializationItem}>
        <div style={styles.specializationInner}>
            <div style={styles.imageContainer}>
                <img src={imageSrc} alt={imageAlt} style={styles.image} />
            </div>
            <div style={styles.contentContainer}>
                <h3 style={styles.title}>{title}</h3>
                <h4 style={styles.subtitle}>{subtitle}</h4>
                <p style={styles.description}>{description}</p>
            </div>
        </div>
    </div>
);

export default function Specializations() {
    useEffect(() => {
        AOS.init({ duration: 1000 })
    }, [])

    const specializationItems = [
        {
            title: "AI",
            subtitle: "Advancing human-AI interaction",
            description: "GenAI Architecture, AI Model Evaluation, AI-Powered UX Design, GenAI Integration",
            imageSrc: "../assets/images/specializations/Specializations9.png",
            imageAlt: "Customer Journey"
        },
        {
            title: "Design",
            subtitle: "Crafting intuitive user experiences",
            description: "User-Centered Design, Interaction Design, Visual Design, Prototyping, Design Systems",
            imageSrc: "../assets/images/specializations/Specializations10.png",
            imageAlt: "Product Strategy"
        },
        {
            title: "Technical",
            subtitle: "Building robust web and mobile solutions",
            description: "React, Expo, HTML, CSS, JavaScript, Python, C++, Git, AWS Cloud Services",
            imageSrc: "../assets/images/specializations/Specializations11.png",
            imageAlt: "Interaction and Motion Design"
        },
        {
            title: "Research",
            subtitle: "Uncovering insights to drive decisions",
            description: "User Research, Persona Modeling, Usability Testing, In-Context Inquiry",
            imageSrc: "../assets/images/specializations/Specializations12.png",
            imageAlt: "Design Operations"
        },
        {
            title: "Tools",
            subtitle: "Leveraging industry-leading Creativity Tools",
            description: "Figma, Adobe CC, InVision, Zeplin, Jira, Asana",
            imageSrc: "../assets/images/specializations/Specializations13.png",
            imageAlt: "Brand & Identity"
        },
        {
            title: "Leadership",
            subtitle: "Guiding teams to innovate and excel",
            description: "Team Management, Process Definition, Strategic Planning, Cross-functional Collaboration",
            imageSrc: "../assets/images/specializations/Specializations14.png",
            imageAlt: "Digital Publications"
        },
        {
            title: "Cloud",
            subtitle: "Architecting scalable AWS solutions",
            description: "AWS Cloud Architecture, Prototyping with Cloud Resources",
            imageSrc: "../assets/images/specializations/Specializations15.png",
            imageAlt: "Rapid Prototyping"
        }
    ];

    return (
        <section className="services-area page-section scroll-to-page" id="specializations">
            <div className="custom-container">
                <div className="services-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                            <i className="las la-stream"></i> Crafts
                        </h4>
                        <h1 className="scroll-animation" data-aos='fade-up'>My <span>Specializations</span></h1>
                    </div>
                    <div className="specializations-container" style={styles.specializationsContainer}>
                        {specializationItems.map((item, index) => (
                            <SpecializationItem key={index} {...item} />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}