import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Resume() {
    useEffect(() => {
        AOS.init({ duration: 1000 })
    }, [])
    return (
        <section className="resume-area page-section scroll-to-page" id="resume">
            <div className="custom-container">
                <div className="resume-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                            <i className="las la-briefcase"></i> Resume
                        </h4>
                        <h1 className="scroll-animation" data-aos='fade-up'>Education & <span>Experience</span></h1>
                    </div>

                    <div className="resume-timeline">
                        <div className="item scroll-animation" data-aos='fade-right'>
                            <span className="date">Sept 2021 - Present</span>
                            <h2>Sr Design Technologist(Gen AI)</h2>
                            <p>Led GenAI integration initiatives, developed GenAI Agentic Architectures, and implemented user-centric interfaces for AI applications.</p>
                            <a
                                className="exp-link"
                                target="_blank"
                                href='https://www.linkedin.com/company/amazon-web-services'>
                                <img className='exp-logo' src='https://media.licdn.com/dms/image/C560BAQER_QnUTXrPJw/company-logo_100_100/0/1670264051233/amazon_web_services_logo?e=1729728000&v=beta&t=19xRq2bw_SzVr6RGhzoOwQR_p9NGvi4h78MlKTwxBlg' />
                                AWS
                            </a>
                        </div>
                        <div className="item scroll-animation" data-aos='fade-right'>
                            <span className="date">Jan 2020 - Aug 2021</span>
                            <h2>UX Alchemist</h2>
                            <p>Led product design initiatives, created user flows, and delivered interactive prototypes for data analytics products.</p>
                            <a
                                className="exp-link"
                                target="_blank"
                                href='https://www.linkedin.com/company/lightupdata/'>
                                <img className='exp-logo' src='../assets/images/logos/lightup.png' />
                                Lightup Data
                            </a>
                        </div>
                        <div className="item scroll-animation" data-aos='fade-right'>
                            <span className="date">Oct 2017 - Jan 2020</span>
                            <h2>UX Engineering Leader</h2>
                            <p>Managed design projects for IntelliFlash, led rebranding efforts, and restructured UX processes.</p>
                            <a
                                className="exp-link"
                                target="_blank"
                                href='https://www.linkedin.com/company/western-digital/'>
                                <img className='exp-logo' src='https://media.licdn.com/dms/image/C4E0BAQGry4WntIJBQg/company-logo_100_100/0/1649866049322/western_digital_logo?e=1729728000&v=beta&t=aaXvd6C7V3Hb-zm5GyyKxG069fJxb-D6BtbH4_OV5lo' />
                                Western Digital DCS | Intelliflash
                            </a>
                        </div>
                        <div className="item scroll-animation" data-aos='fade-right'>
                            <span className="date">June 2014 - Sept 2017</span>
                            <h2>Sr. Manager, UX Engineering</h2>
                            <p>Led reimagination of IntelliFlash OS experience, established in-house design system, and conducted user research.</p>
                            <a
                                className="exp-link"
                                target="_blank"
                                href='https://www.linkedin.com/company/tegile/'>
                                <img className='exp-logo' src='https://media.licdn.com/dms/image/C560BAQFYd6yPzErrFQ/company-logo_100_100/0/1630623078786/tegile_logo?e=1729728000&v=beta&t=rgXstLVIYih5K6N77BvxtWZifu3Ls-9AIcqVRzY3AlI' />
                                Tegile Systems
                            </a>
                        </div>
                        <div className="item scroll-animation" data-aos='fade-right'>
                            <span className="date">Mar 2011 - June 2014</span>
                            <h2>Sr. UX Designer</h2>
                            <p>Served as UX Subject Matter Expert for data replication, designed feature specifications, and contributed to design pattern library.</p>
                            <a
                                className="exp-link"
                                target="_blank"
                                href='https://www.linkedin.com/company/dellemc/'>
                                <img className='exp-logo' src='https://media.licdn.com/dms/image/C4E0BAQHd5Km8_W6GVA/company-logo_100_100/0/1630619397289/delltechnologies_logo?e=1729728000&v=beta&t=B9yLaiu_zyrRnvrYFjlgbRAmineo_73qlbglCaBbqO8' />
                                EMC | Datadomain
                            </a>
                        </div>
                        <div className="item scroll-animation" data-aos='fade-right'>
                            <span className="date">Sept 2005 - Feb 2011</span>
                            <h2>Associate UIX Designer</h2>
                            <p>Designed interface patterns, developed scalable HTML/CSS architectures, and conducted in-context user design.</p>
                            <a
                                className="exp-link"
                                target="_blank"
                                href='https://www.linkedin.com/company/cognizant/'>
                                <img className='exp-logo' src='https://media.licdn.com/dms/image/D4E0BAQHjkUYrDIUKYA/company-logo_100_100/0/1719839886088/cognizant_logo?e=1729728000&v=beta&t=kwTT9UoIYIK9U8ZMz5ZmmmYa_eJtT3-cd4MDXhRhzOk' />
                                Cognizant
                            </a>
                        </div>
                        <div className="item scroll-animation" data-aos='fade-right'>
                            <span className="date">2001 - 2005</span>
                            <h2>B.Tech Electronics and Communications</h2>
                            <a
                                className="exp-link"
                                target="_blank"
                                href='https://www.linkedin.com/company/sastra-deemed-university/'>
                                <img className='exp-logo' src='https://media.licdn.com/dms/image/C4D0BAQE4ha3C_kSahA/company-logo_100_100/0/1631321770714?e=1729728000&v=beta&t=sZX1pMxzW5Mp_OyII3YRa2NaT_IaQlmcMscfwkltxik' />
                                SASTRA
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}
